const timelineData = [
  {
    text: "Research grant",
    date2: "November 2022",
    paragraph: "University of Bergamo",
    first:
      "Main topic: impact of agriculture on the air quality and the COVID-19 pandemic- AgrimOnIA",

    category: {
      tag: "Work",
      color: "#6a5acd",
    },
  },
];

export default timelineData;
